<style scoped>
.modalItem {
  height: 80vh;
  overflow: scroll;
  margin-top: 3px;
  padding: 6px 10px;
  margin: 5px 0;
  text-align: center;
  box-shadow: 0 1px 6px rgb(0 0 0 / 8%);
}
.chooseDateFormItem {
  margin-top: 3px;
  padding: 6px 10px;
  margin: 5px 0;
  box-shadow: 0 1px 6px rgb(0 0 0 / 8%);
  display: flex;
  justify-content: space-between;
  min-height: 41px;
  line-height: 41px;
}
.chooseDateFormItem .chooseDateFormItemTitle {
  font-weight: bold;
  color: #2d4761;
  flex: 1;
}
.chooseDateFormItem .detail {
  flex: 1;
}
</style>
<template>
  <div v-if="showModal">
    <dia-logliu :show="dailogshow" :options="dailogoptions"></dia-logliu>
    <Modal
      v-model="showChooseDate"
      fullscreen
      @onCancel="cancel"
      @onOk="chooseDate"
      :closable="false"
    >
      <p slot="header" style="text-align: center">
        <Icon type="ios-information-circle"></Icon>
        <span>选择日期</span>
      </p>
      <div>
        <div class="chooseDateFormItem">
          <div class="chooseDateFormItemTitle">开始日期</div>
          <div class="detail">
            <DatePicker
              format="yyyy-MM-dd"
              type="date"
              :clearable="false"
              :value="pickerValue"
              @on-change="setStartDateValue"
              placeholder="请选择时间"
              width="200px"
            ></DatePicker>
          </div>
        </div>

        <div class="chooseDateFormItem">
          <div class="chooseDateFormItemTitle">结束日期</div>
          <div class="detail">
            <DatePicker
              format="yyyy-MM-dd"
              type="date"
              :clearable="false"
              :value="pickerEndValue"
              @on-change="setEndDateValue"
              placeholder="请选择时间"
              width="200px"
            ></DatePicker>
          </div>
        </div>
      </div>

      <div slot="footer" style="text-align: center">
        <Button type="info" size="large" @click="chooseDate">确定</Button>
        <Button type="default" size="large" @click="cancel">取消</Button>
      </div>
    </Modal>
  </div>
</template>

<script>
import _ from "lodash";

import diaLogliu from "./dialogLiu.vue";
import { wxConPost, getUserPm } from "../api/httpApi";
import moment from "moment";

let curDate = new Date();

export default {
  name: "dateChooseCom",
  data() {
    return {
      showSuper: false, // 超管显示部门

      chooseZd: true,
      showChooseDate: false,
      // 提示框
      dailogshow: false, // 提示框显示
      duration: 1000,
      dailogoptions: {
        content: "",
        autoClose: true,
        textColor: "#ccc",
      }, // 提示框选项
      // 选择时间
      pickerValue: new Date(curDate.getTime() - 7 * 24 * 60 * 60 * 1000),
      pickerEndValue: curDate,

      startDate: "",
      endDate: "",
      curDateTrpe: "startDate",
    };
  },
  props: {
    title: {
      type: String,
      default: "",
    },
    showChoose: {
      type: Boolean,
      default: false,
    },
    showModal: {
      type: Boolean,
      default: false,
    },
    defaultStartDate: {
      type: String,
      default: "",
    },
    defaultEndDate: {
      type: String,
      default: "",
    },
  },
  watch: {},
  computed: {},
  components: { diaLogliu },
  methods: {
    chooseDate() {
      if (this.startDate && this.endDate && this.startDate > this.endDate) {
        this.dailogshow = true;
        this.dailogoptions.content = "日期选择不合法";
        this.dailogoptions.textColor = "white";
        setTimeout(() => {
          this.dailogshow = false;
        }, 2000);
        return;
      }

      let emitData = {};
      emitData["startDate"] = this.startDate;
      emitData["endDate"] = this.endDate;

      this.$emit("chooseDate", emitData);
    },
    cancel() {
      this.$emit("cancel");
    },

    /****************************** 设置时间 */
    setEndDateValue(date) {
      this.endDate = date;
      this.pickerEndValue = new Date(date);
      if (this.startDate > this.endDate) {
        this.dailogshow = true;
        this.dailogoptions.content = "日期选择不合法";
        this.dailogoptions.textColor = "white";
        setTimeout(() => {
          this.dailogshow = false;
        }, 2000);
        return;
      }
    },
    setStartDateValue(date) {
      this.startDate = date;
      this.pickerValue = new Date(date);
      if (this.endDate && this.startDate > this.endDate) {
        this.dailogshow = true;
        this.dailogoptions.content = "日期选择不合法";
        this.dailogoptions.textColor = "white";
        setTimeout(() => {
          this.dailogshow = false;
        }, 2000);
        return;
      }
    },
  },

  async created() {
    // 初始化日期
    if (this.defaultStartDate) {
      this.startDate = moment(this.defaultStartDate).format("YYYY-MM-DD");
      this.pickerValue = new Date(this.startDate);
    } else {
      this.startDate = moment(new Date(this.pickerValue)).format("YYYY-MM-DD");
    }

    // 初始化结束日期
    if (this.defaultEndDate) {
      this.endDate = moment(this.defaultEndDate).format("YYYY-MM-DD");
      this.pickerEndValue = new Date(this.endDate);
    } else {
      this.endDate = moment(new Date(this.pickerEndValue)).format("YYYY-MM-DD");
    }

    // 验证查询权限
  },
  watch: {
    showModal: function (val) {
      this.showChooseDate = this.showModal;
    },
  },
  beforeDestroy() {
    this.showChooseDate = false;
  },
};
</script>
