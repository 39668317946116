<template>
  <div>
    <div style="display: flex; padding: 10px; font-size: 14px">
      <div style="flex: 1; text-align: left; color: #aaa">
        <span v-if="title">{{ title }}</span>
        统计周期<span v-if="startDate && endDate">
          {{ startDate }} ~ {{ endDate }}
        </span>
        <span v-else> : {{ _startDate }} ~ {{ _endDate }} </span>
      </div>

      <div
        style="
          text-align: right;
          display: flex;
          align-items: center;
          justify-content: center;
        "
        @click="showModal = true"
      >
        <svg
          style="display: block"
          t="1648106151857"
          class="icon"
          viewBox="0 0 1024 1024"
          version="1.1"
          p-id="32361"
          width="16"
          height="16"
        >
          <path
            d="M868.51 355.26v469.63c-0.16 49.15-39.95 88.94-89.1 89.1H237.27c-49.1-0.15-88.89-39.95-89.1-89.1V355.26h720.34z"
            fill="#FFEA8F"
            p-id="32362"
          ></path>
          <path
            d="M453.52 598.92c3.18 3.56 7.72 5.59 12.49 5.62 9.82 0 28.88-10.84 57.18-32.53v152.01a29.055 29.055 0 0 0 5.73 19.84c4.17 4.6 10.17 7.11 16.37 6.86 15.27 0 22.91-11.23 22.91-33.65V525.78a29.75 29.75 0 0 0-5.32-18.93 17.198 17.198 0 0 0-14.22-6.86c-3.67-0.29-7.32 0.81-10.23 3.07-4.33 5.15-8.39 10.5-12.18 16.06a126.918 126.918 0 0 1-53.9 43.58 125.23 125.23 0 0 0-18.93 10.23 14.71 14.71 0 0 0-5.32 12.98c-0.03 4.9 1.93 9.59 5.42 13.01z m0 0"
            p-id="32363"
          ></path>
          <path
            d="M779.67 162.23h-3.93v-14.42c-0.05-38.55-31.32-69.82-69.87-69.87-38.55 0.05-69.77 31.32-69.87 69.87v14.52H380.8v-14.42c-0.05-38.55-31.32-69.82-69.87-69.87-18.5 0-36.23 7.34-49.3 20.47-13.08 13.08-20.47 30.8-20.47 49.3v14.42h-3.88c-66.41 0.21-120.11 54.11-120.16 120.47v542.19c0.26 66.31 54.06 119.95 120.37 120.06h542.19c66.36-0.16 120.11-53.9 120.26-120.26V282.54c-0.17-66.41-53.91-120.15-120.27-120.31z m-112.56-14.42c0.05-21.34 17.36-38.61 38.66-38.66 21.34 0.05 38.61 17.31 38.71 38.66v60.05c-0.1 21.29-17.36 38.61-38.71 38.66-21.29-0.05-38.61-17.36-38.66-38.66v-60.05z m-395.06 0c0-10.28 4.08-20.05 11.37-27.34 7.23-7.29 17.16-11.37 27.44-11.32 21.29 0.1 38.5 17.36 38.55 38.66v60.47c0 10.23-4.13 20.05-11.37 27.29l-0.62 0.72c-7.29 6.67-16.8 10.34-26.72 10.23-21.35 0-38.66-17.31-38.66-38.66v-60.05z m596.46 677.08c-0.16 49.15-39.95 88.94-89.1 89.1H237.27c-49.1-0.15-88.89-39.95-89.1-89.1V355.26H868.5v469.63z m0.2-500.85H148.28v-41.5c0.16-49.15 40-88.94 89.1-89.1h3.88l0.1 14.52c0 18.5 7.39 36.23 20.47 49.3 13.07 13.08 30.8 20.47 49.3 20.47 18.09-0.05 35.45-7.08 48.37-19.64l0.93-0.93a69.531 69.531 0 0 0 20.47-49.3v-14.42H636v14.42c0.1 38.55 31.32 69.77 69.87 69.87 38.55-0.1 69.82-31.32 69.87-69.87v-14.42h3.93c49.1 0.16 88.89 39.95 89.05 89.1v41.5z"
            p-id="32364"
          ></path>
          <path
            d="M744.47 147.81v60.05c-0.1 21.29-17.36 38.61-38.71 38.66-21.29-0.05-38.61-17.36-38.66-38.66v-60.05c0.05-21.34 17.36-38.61 38.66-38.66 21.35 0.06 38.61 17.32 38.71 38.66zM349.42 147.81v60.47c0 10.23-4.13 20.05-11.37 27.29l-0.62 0.72c-7.29 6.67-16.8 10.34-26.72 10.23-21.35 0-38.66-17.31-38.66-38.66v-60.05c0-10.28 4.08-20.05 11.37-27.34 7.23-7.29 17.16-11.37 27.44-11.32 21.3 0.11 38.51 17.37 38.56 38.66z"
            fill="#80D8FF"
            p-id="32365"
          ></path>
          <path
            d="M868.71 282.54v41.5H148.28v-41.5c0.16-49.15 40-88.94 89.1-89.1h3.88l0.1 14.52c0 18.5 7.39 36.23 20.47 49.3 13.07 13.08 30.8 20.47 49.3 20.47 18.09-0.05 35.45-7.08 48.37-19.64l0.93-0.93a69.531 69.531 0 0 0 20.47-49.3v-14.42H636v14.42c0.1 38.55 31.32 69.77 69.87 69.87 38.55-0.1 69.82-31.32 69.87-69.87v-14.42h3.93c49.09 0.16 88.89 39.96 89.04 89.1z"
            fill="#FD5E7A"
            p-id="32366"
          ></path>
        </svg>

        <span style="font-weight: bold">筛选日期</span>
      </div>

      <date-choose-com
        :defaultEndDate="endDate"
        :defaultStartDate="startDate"
        :showModal="showModal"
        @chooseDate="chooseDate"
        @cancel="showModal = false"
      ></date-choose-com>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import moment from "moment";
import dateChooseCom from "./dateChooseCom.vue";
// import

export default {
  name: "dateAndDescCom",
  components: { dateChooseCom },
  props: {
    startDate: {
      type: String,
      default: "",
    },
    endDate: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      showModal: false,
      // 选择日期
      _startDate: "",
      _endDate: "",
      // 是否显示提示框
      descStatus: false,
    };
  },
  methods: {
    chooseDate(emitData) {
      this.showModal = false;
      this.$emit("chooseDate", {
        startDate: emitData.startDate,
        endDate: emitData.endDate,
      });
    },
  },
  async created() {
    this._startDate =
      this.startDate || moment().subtract(7, "day").format("YYYY-MM-DD");
    this._endDate = this.endDate || moment().format("YYYY-MM-DD");
  },
};
</script>

<style scoped>
.statement .statementContent {
  background: white;
  color: #6a6f7d;
  font-weight: bold;
  border-radius: 10px;
  width: 96%;
  overflow: scroll;
  position: relative;
  z-index: 1009;
}

.statementContent p {
  margin-bottom: 10px;
}
</style>
